import React from "react";
import { graphql } from "gatsby";
// import pathBuilder from "../utils/pathBuilder"
// import Layout from "../../components/Layout/Layout"
// import styled from "styled-components"
// import theme from "../theme/variables"
import PrivateLessonsTemplate from "../../templates/private-lessons/private-lessons";

const environment = {
  lang: "en",
  path: "/en/private-lessons",
  altHrefLang: [
    {
      lang: "nl",
      path: "/private-lessons",
    },
    {
      lang: "en",
      path: "/en/private-lessons",
    },
  ],

  theme: "green",
};

const PrivateLessonsEn = (props) => {
  return <PrivateLessonsTemplate environment={environment} {...props} />;
};

export default PrivateLessonsEn;

export const query = graphql`
  query videoPrivateLessonsEnQuery {
    video: contentfulAsset(
      contentful_id: { eq: "2F0Gv3vCsAsPWfYpQgti69" }
      node_locale: { eq: "en" }
    ) {
      contentful_id
      file {
        url
      }
    }
  }
`;
